// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$halleskogens-primary: mat.define-palette(mat.$indigo-palette);
$halleskogens-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$halleskogens-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$halleskogens-theme: mat.define-light-theme(
  $halleskogens-primary,
  $halleskogens-accent,
  $halleskogens-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($halleskogens-theme);

/* You can add global styles to this file, and also import other style files */

body {
  overflow-x: hidden;
}

/* Remove all margins */
h1,
h2,
h3,
h4,
h5,
h6,
p,
header,
body {
  font-family: "Roboto", cursive, sans-serif;
  margin: 0;
  padding: 0;
}

/* Hero text */
h1 {
  font-size: 2em;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: white;
  text-shadow: 0px 0px 5px #000000;
}

/* Section heading */
h2 {
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #333;
}

/* Section sub heading */
h3 {
  font-size: 1.2em;
  font-weight: 300;
  letter-spacing: 0.05em;
  color: #333;
}

/* Card heading */
h4 {
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #333;
}

/* Card sub heading */
h5 {
  font-size: 1em;
  font-weight: 300;
  letter-spacing: 0.05em;
  color: #333;
}

p {
  color: #333;
}
